<template>
    <div class="body">
        <div class="main">
            <div class="biaoti">请输入激活码</div>
            <el-input v-model="value" placeholder="请输入激活码" class="inputclass" />
            <!-- <input type="text" /> -->
            <div class="buttonClass">
                <a-button type="primary" @click="handleData"> 激活 </a-button>
            </div>
        </div>
    </div>
</template>

<script>
    // import * as Server from '@/api/server.js'
    import { Input } from 'element-ui'
    import Vue from 'vue'
    import * as Server from '@/api/server'

    Vue.use(Input)
    export default {
        name: 'code',
        data() {
            return {
                value: '',
            }
        },
        methods: {
            async handleData() {
                if (this.value) {
                    let data = {
                        method: 'POST',
                        url: 'd/inviteCode/bind/init',
                        config: {
                            data: {
                                inviteCode: this.value,
                                macAddress: await window.electronAPI.getMac(),
                            },
                        },
                        loading: false,
                    }

                    Server.Axios(data)
                        .then((res) => {
                            if (res.data.returnCode == 200) {
                                this.$message.success('激活成功')
                                this.$router.replace({ name: 'Home' })
                            } else {
                                this.$message.info(res.data.returnInfo)
                            }
                        })
                        .catch((res) => {
                            this.$message.info('发生错误，请重试')
                        })
                } else {
                    this.$message.warning('请填写激活码')
                }
            },
        },
    }
</script>

<style lang="less" type="text/less" scoped>
    .body {
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f5f5f5;
        overflow: hidden;
        .main {
            width: 500px;
            height: 300px;
            background: #fff;
            border-radius: 20px;
            box-shadow: 2px 3px 5px 5px #e7e7e7;
            padding: 0 50px;
            .biaoti {
                font-size: 24px;
                margin-top: 50px;
                text-align: center;
            }
            .inputclass {
                margin-top: 40px;
            }
            .buttonClass {
                display: flex;
                justify-content: center;
                margin-top: 30px;
                .ant-btn {
                    height: 40px;
                    width: 100%;
                }
            }
        }
    }
</style>
